<template>
  <div class="flex-container non-scrollable">
    <page-title value="Services"/>
    <div class="flex-container non-scrollable">
      <a-d-v-tableau-services ref="advTable" :key="keyTable" @select="selectService"
                              @delete-service="(payloadService) => deleteServices([payloadService])">
        <div v-if="hasAccess($route.name, true)" class="flex flex-row-reverse">
          <Button v-if="services.find((s) => s.selected)" type="button" @click="editInvoice">Éditer la facture</Button>
          <Button type="button" :class="services.find((s) => s.selected) ? 'p-button-outlined mr-2' : ''" @click="openServiceDialog(null)">Créer un nouveau service</Button>
          <Button class="p-button-danger mr-2" v-if="services.find((s) => s.selected)" type="button" @click="deleteServices">Supprimer les services</Button>
        </div>
      </a-d-v-tableau-services>
    </div>
    <Dialog :draggable="false" dismissable-mask v-model:visible="serviceDialog" :style="{width: '1000px'}" :modal="true" :show-header="false">
      <creation-service @create="create" :service="service" @change="(val) => service=val"/>
    </Dialog>
  </div>
</template>

<script>
import CreationService from "@/views/Pages/ADV/CreationService";
import ADVTableauServices from "@/components/ADVTableauServices";
import { mapGetters } from 'vuex'
import {hasAccess} from "@/utils/Misc";
import PageTitle from "@/components/PageTitle.vue";
export default {
  name: "Services",
  components: {PageTitle, ADVTableauServices, CreationService},
  data () {
    return {
      hasAccess: hasAccess,
      serviceDialog: false,
      service: null,
      keyTable: 0
    }
  },
  computed: {
    ...mapGetters({
      services: 'service/getServices'
    })
  },
  methods: {
    deleteServices (services = []) {
      if (services?.length > 0) {
        this.services.forEach((arrayService) => {
          if (services?.find(service => service?.id === arrayService?.id) !== undefined) {
            arrayService.selected = true
          }
        })
      }

      // todo: should be done in back too !!
      // Vérifier que tous les services d'une même déclaration de facturation sont sélectionnés
      let missingServices = []
      if (this.services
          .filter(s => s.selected)
          .find(s => {
            if (s.source && s.source.length && s.source[0].collection === "DeclarationFacturation") {
              missingServices = this.services
                  .filter(service => service.source && service.source?.length && !service.selected &&
                      service.source[0]?.collection === "DeclarationFacturation" &&
                      service.source[0]?.item?.id === s.source[0]?.item?.id
                  )
              return missingServices.length !== 0
            }
            return false
          })
      ) {
        this.$toast.add({severity:'error', summary:'Erreur', detail: 'Toutes les prestations d\'une même déclaration doivent être sélectionnés', life: 3000})
        missingServices.forEach(ms => {
          ms.selected = true
        })
      } else {
        this.$confirm.require({
          header: 'Supprimer les services',
          message: 'Vous êtes sur le point de supprimer les services sélectionnés',
          acceptLabel: 'Confirmer',
          rejectLabel: 'Annuler',
          accept: async () => {
            this.$store.state.misc.loading = true
            await this.$store.dispatch('service/removeServices', {
                  ids: this.services.filter((s) => s.selected).map(service => service.id)
                }
            )
            this.keyTable++
            this.$toast.add({severity:'success', summary:'Succès', detail:'Services supprimés', life: 3000});
            this.$store.state.misc.loading = false
            this.$confirm.close()
          },
          reject: () => {
            this.$confirm.close()
          }
        })
      }
    },
    openServiceDialog (service) {
      this.service = service
      this.serviceDialog = true
    },
    closeServiceDialog () {
      this.service = null
      this.serviceDialog = false
      this.$refs.advTable.getAllServices()
    },
    create (val) {
      this.$store.state.misc.loading = true

      this.$store.dispatch('service/saveService', val).then(() => {
        this.$store.state.misc.loading = false
        this.closeServiceDialog()
        this.$toast.add({severity:'success', summary:'Succès', detail:'Service créé', life: 3000});
      }).catch(() => {
        this.$store.state.misc.loading = false
        this.$toast.add({severity:'error', summary:'Erreur', detail:'Une erreur est survenue', life: 3000});
      })
    },
    // validate (val) {
      // val.status = 'invoicing'
      // this.$store.dispatch('service/saveService', val).then(() => {
      //   this.closeServiceDialog()
      // })
    // },
    selectService (service) {
      this.openServiceDialog(service)
    },
    async editInvoice () {
      this.$store.state.misc.loading = true

      let servicesToBill = this.services.find((s) => s.selected)
      if (!servicesToBill) {
        return
      }

      const ndfs = await this.$store.dispatch('ndf/getAllNDFSByIndependent', servicesToBill.independent.id)
      this.$store.state.misc.loading = false
      if (ndfs.find(n => n.adminStatus === "validate")) {
        this.$confirm.require({
          header: 'Créer une facture',
          message: 'Une ou plusieurs notes de frais sont à valider pour cet indépendent.',
          acceptLabel: 'Continuer quand même',
          rejectLabel: 'Valider les NDF',
          accept: async () => {
            this.$store.commit('invoice/SET_INVOICE', null)

            await this.$store.dispatch('independent/getOneIndependent', servicesToBill.independent.id)
            await this.$store.dispatch('mission/getOneMission', servicesToBill.mission.id)

            this.$router.push({name: 'Facture'})
            this.$confirm.close()
          },
          reject: () => {
            this.$router.push({name: 'NDFAValider'})
            this.$confirm.close()
          }
        })
      } else {
        this.$store.commit('invoice/SET_INVOICE', null)

        await this.$store.dispatch('independent/getOneIndependent', servicesToBill.independent.id)
        await this.$store.dispatch('mission/getOneMission', servicesToBill.mission.id)

        this.$router.push({name: 'Facture'})
      }
    }
  }
}
</script>

<style scoped>

</style>
