<template>
  <div class="flex-container non-scrollable">
    <DataTable ref="dt" :value="services.filter(s => s.avoir === null)" :paginator="true" class="p-datatable-customers"
               :rows="constants.defaultRowNumber" selection-mode="single"
               dataKey="id" :rowHover="true" v-model:filters="filters" filterDisplay="menu" :loading="loading"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="constants.rowsPerPageOptions"
               :currentPageReportTemplate="$t('datatable.currentPageReportTemplate', {first: '{first}', last: '{last}', totalRecords: '{totalRecords}', target: $t('datatable.target.services')})"
               scrollable scroll-height="flex" sort-field="status" :sort-order="1" @rowSelect="clickService">
      <template #header>
        <div class="p-d-flex flex">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" :placeholder="$t('search')" />
          </span>
        </div>
      </template>
      <template #empty>
        {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
      </template>
      <template #loading>
        {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
      </template>
      <Column field="selected" class="non-clickable-column">
        <template #header>
          <Checkbox v-if="showCheckboxHeader" v-model="allLinesChecked" type="text" binary @change="checkAllServices"/>
        </template>
        <template #body="{ data, field}">
          <Checkbox v-if="data.status === 'toInvoice'" :binary="true" v-model="data[field]" @click.stop="changeSelected(data)"></Checkbox>
        </template>
      </Column>
      <Column field="moisFacturation" :header="$t('month').toUpperCase()" sortable filterField="moisFacturation" sortField="moisFacturation" :showFilterMatchModes="false" style="min-width:12rem" :show-apply-button="false" :show-clear-button="false">
        <template #body="{data}">
          <span class="uppercase">
            {{
              data.moisFacturation.toLocaleDateString('fr-FR', {
                month: 'long',
                year: 'numeric',
              })
            }}
          </span>
        </template>
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">{{ $t('month') }}</div>
          <MultiSelect v-model="filterModel.value" :options="commonFilter('moisFacturation')" option-value="value"
                       optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem"
                       @change="filterCallback()">
            <template #option="slotProps">
              <div>
                <span class="uppercase">{{ slotProps.option.value?.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' }) }}</span>
              </div>
            </template>
            <template #value="slotProps">
              <div>
                <span class="uppercase">{{ slotProps.value ? slotProps.value.map((v) => v.toLocaleDateString('fr-FR', {month: 'long',year: 'numeric',})).join(', ') : 'Mois' }}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="independent.last_name" :header="$t('lastname').toUpperCase()" sortable filterField="independent.last_name" sortField="independent.last_name" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">{{ $t('lastname') }}</div>
          <MultiSelect v-model="filterModel.value" :options="commonFilter('independent.last_name')"
                       option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter"
                       style="max-width: 14rem; min-width: 14rem" @change="filterCallback()">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="independent.first_name" :header="$t('firstname').toUpperCase()" sortable filterField="independent.first_name" sortField="independent.first_name" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">{{ $t('firstname') }}</div>
          <MultiSelect v-model="filterModel.value" :options="commonFilter('independent.first_name')"
                       option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter"
                       style="max-width: 14rem; min-width: 14rem" @change="filterCallback()">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="label" :header="$t('service').toUpperCase()" sortable filterField="label" sortField="label" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">{{ $t('service') }}</div>
          <MultiSelect v-model="filterModel.value" :options="commonFilter('label')" option-value="value"
                       optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem"
                       @change="filterCallback()">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="quantity" :header="$t('columnWorkedDaysOnWorkableDays')">
        <template #body="{data}">
          <span>{{ getServiceWorkedDays(data) }}</span>
        </template>
      </Column>
      <Column field="mission.clientContract.name" :header="$t('client').toUpperCase()" sortable filterField="mission.clientContract.name" sortField="mission.clientContract.name" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">{{ $t('client') }}</div>
          <MultiSelect v-model="filterModel.value" :options="commonFilter('mission.clientContract.name')"
                       option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter"
                       style="max-width: 14rem; min-width: 14rem" @change="filterCallback()">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="mission.jsonCost.settlementPeriod.value" :header="$t('settlementDelay').toUpperCase()" sortable filterField="mission.jsonCost.settlementPeriod.value" sortField="mission.jsonCost.settlementPeriod.value" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
        <template #body="{data}">
          {{ data.mission?.jsonCost?.settlementPeriod.value }} {{ data.mission?.jsonCost?.settlementPeriod.unit }}
        </template>
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">{{ $t('settlementDelay') }}</div>
          <MultiSelect v-model="filterModel.value" :options="commonFilter('mission.jsonCost.settlementPeriod.value')"
                       option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem"
                       @change="filterCallback()">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}} Jours</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="status" :header="$t('etat').toUpperCase()" sortable filterField="status" :sort-field="sortByStatus" :showFilterMatchModes="false" style="min-width:10rem" :show-apply-button="false" :show-clear-button="false">
        <template #body="{data}">
          <Chip :class="'custom-chip-' + data.status" :label="getStatusTranslation(data.status).toUpperCase()">
          </Chip>
        </template>
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">{{ $t('etat') }}</div>
          <MultiSelect v-model="filterModel.value" :options="commonFilter('status')" option-value="value"
                       optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem"
                       @change="filterCallback()">
            <template #option="slotProps">
              <Chip :class="'custom-chip-' + slotProps.option.value" :label="getStatusTranslation(slotProps.option.value).toUpperCase()"></Chip>
            </template>
            <template #value="slotProps">
              <div>
                <span>{{ slotProps.value ? slotProps.value.map((v) => getStatusTranslation(v)).join(', ') : 'ETAT' }}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="invoice" :header="$t('invoice').toUpperCase()" sortable sortField="invoice">
        <template #body="{data}">
          {{data.invoice ? (data.status === 'invoiced' ? data.invoice.invoice_number : 'Brouillon') : ''}}
        </template>
      </Column>
      <Column>
        <template #body="{ data }">
          <Button severity="danger" type="button" icon="pi pi-trash" size="small" rounded @click="deleteService(data)"/>
        </template>
      </Column>
      <template #footer>
        <slot/>
      </template>
    </DataTable>
  </div>
  <dialog-n-d-f-admin :key="keyDialog" :dialog="dialogNdf" :ndf="selectedNdf" @close="dialogNdf = false"></dialog-n-d-f-admin>

  <dialog-prestation v-if="selectedService" :key="keyDialog" :dialog="dialogPrestation" :service="selectedService" @close="dialogPrestation = false"></dialog-prestation>

  <dialog-facture-fournisseur-admin :key="keyDialog" :missions="missions" :independents="independents" :dialog="dialogFf" @close="dialogFf = false" :facturefournisseur="selectedFf"></dialog-facture-fournisseur-admin>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import {mapGetters, mapState} from 'vuex'
import DialogNDFAdmin from "@/components/NDF/DialogNDFAdmin";
import DialogPrestation from "@/components/DialogPrestation";
import DialogFactureFournisseurAdmin from "@/components/NDF/DialogFactureFournisseurAdmin";
export default {
  name: "ADVTableauServices",
  emits: ['select', 'deleteService'],
  components: {DialogFactureFournisseurAdmin, DialogPrestation, DialogNDFAdmin},
  data() {
    return {
      keyDialog: 0,
      dialogNdf: false,
      selectedNdf: null,
      selectedService: null,
      dialogPrestation: false,
      customers: null,
      selectedCustomers: null,
      allLinesChecked: null,
      serviceSelected: null,
      dialogFf: false,
      selectedFf: null,
      showCheckboxHeader: false,
      filters: {
        'global': {value: null, matchMode: null},
        'independent.last_name': {value: null, matchMode: FilterMatchMode.IN},
        'independent.first_name': {value: null, matchMode: FilterMatchMode.IN},
        'reference': {value: null, matchMode: FilterMatchMode.IN},
        'moisFacturation': {value: null, matchMode: FilterMatchMode.IN},
        'label': {value: null, matchMode: FilterMatchMode.IN},
        'mission.clientContract.name': {value: null, matchMode: FilterMatchMode.IN},
        'mission.jsonCost.settlementPeriod.value': {value: null, matchMode: FilterMatchMode.IN},
        'status': {value: ['toInvoice'], matchMode: FilterMatchMode.IN},
      },
      loading: true,
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants,
    }),
    ...mapGetters({
      services: 'service/getServices',
      missions: 'mission/getMissions',
      independents: 'independent/getIndependents',
    })
  },
  watch: {
    'filters': {
      async handler () {
        process.nextTick(async () => {
          await this.showCheckHeader()
        })
      },
      deep: true
    }
  },
  async mounted() {
    await this.$store.dispatch('mission/getAllMissionsForEmployees')
    await this.$store.dispatch('independent/getAllIndependents')
    this.showCheckboxHeader = false
    this.getAllServices()
  },
  methods: {
    deleteService (service) {
      this.$emit('deleteService', service)
    },
    commonFilter (field) {
      let array = this.services.map((s) => {
        let fields = field.split('.')
        let v = s
        fields.forEach((f) => {
          v = v[f]
        })
        return v
      })
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item || Math.abs(fa.value - item) === 0)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    getStatusTranslation (status) {
      switch (status) {
        case 'toInvoice':
          return 'À facturer'
        case 'invoicing':
          return 'En cours'
        case 'invoiced':
          return 'Facturé'
      }
      return ''
    },
    getServiceWorkedDays (service) {
      return service?.source[0]?.collection === 'DeclarationFacturation' ?
          `${service?.quantity} / ${service?.workableDays} ${service?.mission.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE
          || service?.mission.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE ? 'Jours' : 'Heures'}`
          : `Astreinte ou NDF refacturable`
    },
    clickService (service) {
      service = service.data
      if (service.status === this.constants.SERVICE_STATUS.TO_INVOICE) {
        // todo: hot fix: maybe there's a better solution to fix this
        service.selected = !service.selected
        this.changeSelected(service).then(() => {
          this.$store.dispatch('service/changeService', { ...service, selected: service.selected })
        })
      } else {
        this.selectService(service)
      }
    },
    selectService (service) {
      if (service.source.length > 0) {
        if (service.source[0].collection === 'ndf') {
          this.selectedNdf = service.source[0].item
          this.selectedNdf.date = new Date(this.selectedNdf.date)
          this.selectedNdf.depenses.map(dep => {
            if (!dep.processed) {
              dep.date = new Date(dep.date)
              dep.ttc /= 100
              dep.ht /= 100
              dep.tva /= 100
              dep.item?.forEach((sousdepense) => {
                sousdepense.ttc = sousdepense.ttc / 100
                sousdepense.ht = sousdepense.ht / 100
                sousdepense.tva = sousdepense.tva / 100
              })
            }
            dep.processed = true
          })
          this.selectedNdf.ikm.map(ikm => {
            ikm.distanceTotale = (ikm.distanceTrajet * ikm.nombreTrajets)
            ikm.dates = ikm.dates.map(d => new Date(d))
          })
          this.keyDialog++
          this.dialogNdf = true
        } else if (service.source[0].collection === 'DeclarationFacturation') {
          this.selectedService = service
          this.keyDialog++
          this.dialogPrestation = true
        } else if (service.source[0].collection === 'FactureFournisseur') {
          this.selectedFf = service.source[0].item
          this.selectedFf.date = new Date(this.selectedFf.date)
          this.keyDialog++
          this.dialogFf = true
        } else {
          this.$emit('select', service)
        }
      } else {
        this.$emit('select', service)
      }
    },
    getAllServices() {
      this.$store.dispatch('service/getAllServices').then(async () => {
        this.loading = false;
        await this.showCheckHeader()
      })
    },
    // eslint-disable-next-line no-unused-vars
    async checkAllServices () {
      process.nextTick(async () => {
        const servicesProcessed = await this.$refs.dt.processedData
        let isServiceFound = false
        this.services.forEach((service) => {
          isServiceFound = servicesProcessed.findIndex((serviceProcessed) => serviceProcessed.id === service.id) !== -1
          if (isServiceFound) {
            service.selected = this.allLinesChecked
          }
        })
        if (!this.allLinesChecked) {
          this.serviceSelected = null
          this.resetFilters()
        }
      })
    },
    async showCheckHeader () {
      const servicesProcessed = await this.$refs.dt.processedData
      this.showCheckboxHeader = this.serviceSelected !== null && servicesProcessed.every((service) => !this.isDifferentFromCurrentSelectedService(service))
    },
    isDifferentFromCurrentSelectedService (service) {
      if (!this.serviceSelected?.independent?.id || !this.serviceSelected?.mission?.id) return false
      if (this.serviceSelected?.independent?.id !== service.independent.id || this.serviceSelected?.mission?.id !== service.mission.id) {
        service.selected = false
        return true
      }
      return false
    },
    resetFilters () {
      const lastFilterStatus = ['toInvoice']
      for (let filterKey in this.filters) {
        if (filterKey !== 'global') {
          this.filters[filterKey].value = []
        }
      }
      this.filters['status'].value = lastFilterStatus
    },
    async changeSelected(service) {
      return new Promise((resolve) => {
        process.nextTick(async () => {
          if (service.selected) {
            if (this.isDifferentFromCurrentSelectedService(service)) {
              await this.showCheckHeader()
              resolve()
              return
            }
            this.serviceSelected = service
            let filters = ['independent.last_name', 'independent.first_name', 'mission.clientContract.name', 'status']
            filters.forEach((filter) => {
              if (!this.filters[filter].value) {
                this.filters[filter].value = []
              }
              let fields = filter.split('.')
              let value = service
              fields.forEach((f) => {
                value = value[f]
              })
              if (this.filters[filter].value.indexOf(value) === -1) {
                this.filters[filter].value.push(value)
              }
            })
          } else {
            if (service.id === this.serviceSelected?.id) {
              this.serviceSelected = null
            }
            if (this.services.filter((s) => s.selected).length === 0) {
              this.resetFilters()
            }
          }
          await this.showCheckHeader()
          resolve()
        })
      })
    },
    sortByStatus (item) {
      let sortValue = 0
      switch (item.status) {
        case this.constants.SERVICE_STATUS.TO_INVOICE:
          sortValue = 1
          break
        case this.constants.SERVICE_STATUS.INVOICING:
          sortValue = 2
          break
        case this.constants.SERVICE_STATUS.INVOICED:
          sortValue = 3
          break
        default:
          sortValue = 4
          break
      }
      return sortValue
    },
  }
}
</script>

<style scoped>
.custom-chip-toInvoice {
  background-color: #feffcc;
  color: #dbdf0c;
}
.custom-chip-invoicing {
  background-color: #cce4ff;
  color: #2E6BAB;
}
.custom-chip-invoiced {
  background-color: #ebffcc;
  color: #97C353;
}
</style>


