<template>
  <div>
    <Dialog dismissable-mask :draggable="false" v-model:visible="showDialogLocal" :style="{width: '30%'}" header="Prestation" :modal="true" @hide="$emit('close')">
      <div class="flex flex-column gap-3">
        <div class="field">
          <label for="mois">Mois de facturation</label>
          <Dropdown id="mois" :model-value="mois[df.month]" :options="mois" disabled/>
        </div>
        <div class="field">
          <label for="nbJours">Nombre de jours</label>
          <InputNumber id="nbJours" disabled :model-value="service.quantity" highlightOnFocus/>
        </div>
        <div class="field align-items-center">
          <label for="montant">Montant</label>
          <InputNumber id="montant" disabled :model-value="service.price" locale="fr-FR" mode="currency" currency="EUR" highlightOnFocus/>
        </div>
        <div class="field align-items-center">
          <label for="commentaire">Commentaire</label>
          <Textarea  disabled id="commentaire" :model-value="service.commentaire" rows="5" cols="30" />
        </div>
        <div class="flex align-items-center justify-content-around">
          <Button v-if="df?.craFile" type="button" label="Télécharger CRA" @click="downloadCra"/>
          <Button type="button" label="Voir la fiche mission" class="justify-content-center" @click="openFicheMission"/>
        </div>
      </div>
    </Dialog>
    <Dialog :draggable="false" v-model:visible="showDialogMission" :style="{width: '70%'}" :modal="true" dismissable-mask class="p-fluid">
      <template #header>
        <h3 class="mb-2">Fiche Mission</h3>
      </template>
      <fiche-mission
          ref="mission"
          :key="missionKeyComp"
          :mission="service.mission"
          :editable="false"
          :independentId="service.independent.id"
      />
    </Dialog>
  </div>
</template>

<script>
import FicheMission from "@/components/FicheMission";

export default {
  components: {FicheMission},
  props: ["service", "dialog"],
  emits: ["close"],
  name: "DialogPrestation",
  data () {
    return {
      showDialogLocal: this.dialog,
      missionKeyComp: 1,
      showDialogMission: false,
      df: this.service.source[0].item,
      mois: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    }
  },
  methods: {
    openFicheMission () {
      this.missionKeyComp++
      this.showDialogMission = true
    },
    async downloadCra () {
      this.$store.state.misc.loading = true
      const file = await this.$store.dispatch('file/downloadFile', {id: this.df.craFile })
      const blob = new Blob([new Uint8Array(file.data.data)], { type: file.mimetype })
      const link = document.createElement('a')
      link.download = file.filename
      const url = URL.createObjectURL(blob)
      link.href = url
      link.click()
      URL.revokeObjectURL(url)
      this.$store.state.misc.loading = false
    }
  }
}
</script>

<style scoped>

</style>
